import { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useColorMode,
} from "@chakra-ui/react";

function ModalDialog({ isOpen, onClose, onAddPersonalityType }) {
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const { colorMode } = useColorMode();

  const handleInputChange = (field) => (e) => {
    if (field === "jobTitle") {
      setJobTitle(e.target.value);
    } else if (field === "companyName") {
      setCompanyName(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddPersonalityType({
      jobTitle,
      companyName,
    });
    setJobTitle("");
    setCompanyName("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent bg={colorMode === "light" ? "#fbf2f2" : "gray.800"}>
        <ModalHeader>Add Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Job Title</FormLabel>
                <Input
                  value={jobTitle}
                  onChange={handleInputChange("jobTitle")}
                  placeholder="Enter job title"
                  bgColor={colorMode === "light" ? "white" : "gray.600"}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input
                  value={companyName}
                  onChange={handleInputChange("companyName")}
                  placeholder="Enter company name"
                  bgColor={colorMode === "light" ? "white" : "gray.600"}
                />
              </FormControl>
              <Button type="submit" colorScheme="purple">
                Add
              </Button>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="purple" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalDialog;
