import { useState, useEffect } from "react";
import { Box, Center, ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import Banner from "./components/Banner";
import { auth } from "./firebase";
import { Route, Routes } from "react-router-dom";
import CoverLetterList from "./components/CoverLetterList";
import UploadForm from "./pages/UploadForm";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};
const theme = extendTheme({
  config,
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  styles: {
    global: (props) => ({
      body: {
        background:
          props.colorMode === "light"
            ? "linear-gradient(160deg, #fbf2f2 80%, purple 100%)"
            : "linear-gradient(160deg, black 60%, purple 100%)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed", // Added line
      },
    }),
  },
  colors: {
    purple: {
      500: "purple",
    },
  },
});
function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    });

    // Cleanup subscription
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Banner />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Center height="80vh" width="100vw">
              <Box width="15vw" height="50vh">
                <ins
                  className="adsbygoogle"
                  style={{ display: "block" }}
                  data-ad-client="ca-pub-8970634072529097"
                  data-ad-slot="6781012957"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                  data-ad-size="auto"
                />
              </Box>
              <UploadForm currentUser={currentUser} theme={theme} />
              <Box width="15vw" height="50vh">
                <ins
                  className="adsbygoogle"
                  style={{ display: "block" }}
                  data-ad-client="ca-pub-8970634072529097"
                  data-ad-slot="5508293881"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                  data-ad-size="auto"
                ></ins>
              </Box>
            </Center>
          }
        />
        <Route
          path="/home"
          element={
            <Center height="100vh">
              <CoverLetterList userId={currentUser?.uid} theme={theme} />
            </Center>
          }
        />
      </Routes>
    </ChakraProvider>
  );
}
export default App;
