import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tooltip,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  Input,
  useColorMode,
  ModalFooter,
} from "@chakra-ui/react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { FiCopy } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";

function CoverLetterList({ userId, theme }) {
  const [coverLetters, setCoverLetters] = useState([]);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCoverLetters, setFilteredCoverLetters] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const { colorMode } = useColorMode();
  const toast = useToast();

  useEffect(() => {
    if (userId) {
      const coverLettersRef = collection(db, "coverLetters");
      const q = query(coverLettersRef, where("userId", "==", userId));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const letters = [];
        querySnapshot.forEach((doc) => {
          letters.push(doc.data());
        });
        setCoverLetters(letters);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [userId]);

  useEffect(() => {
    // Filter cover letters based on search term
    const filtered = coverLetters.filter((coverLetter) =>
      Object.values(coverLetter).some((value) =>
        value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredCoverLetters(filtered);
  }, [coverLetters, searchTerm]);

  const handleCoverLetterClick = (coverLetter) => {
    setSelectedCoverLetter(coverLetter);
    setIsModalOpen(true);
  };

  const handleCopyText = () => {
    setIsCopied(true);
    toast({
      title: "Text Copied!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box height="90%" margin="50px">
      <Box mb={4}>
        <Input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchTermChange}
          fontColor={"white"}
          bg={colorMode === "dark" ? "black" : "white"}
          color={colorMode === "dark" ? "white" : "black"}
          _placeholder={{ color: colorMode === "dark" ? "gray.300" : "gray" }}
        />
      </Box>

      <Table>
        <Thead>
          <Tr>
            <Th>Job Title</Th>
            <Th>Company Name</Th>
            <Th>Cover Letter Text</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredCoverLetters.map((coverLetter, index) => (
            <Tr key={index} onClick={() => handleCoverLetterClick(coverLetter)}>
              <Td
                bg={
                  index % 2 === 0
                    ? colorMode === "light"
                      ? "gray.50"
                      : "gray.700"
                    : colorMode === "light"
                    ? "white"
                    : "gray.600"
                }
                color={colorMode === "light" ? "black" : "white"}
              >
                {coverLetter.jobTitle}
              </Td>
              <Td
                bg={
                  index % 2 === 0
                    ? colorMode === "light"
                      ? "gray.50"
                      : "gray.700"
                    : colorMode === "light"
                    ? "white"
                    : "gray.600"
                }
                color={colorMode === "light" ? "black" : "white"}
              >
                {coverLetter.companyName}
              </Td>
              <Td
                bg={
                  index % 2 === 0
                    ? colorMode === "light"
                      ? "gray.50"
                      : "gray.700"
                    : colorMode === "light"
                    ? "white"
                    : "gray.600"
                }
                color={colorMode === "light" ? "black" : "white"}
                _hover={{
                  bg: colorMode === "light" ? "lightgray" : "gray.800",
                  cursor: "pointer",
                }}
              >
                {coverLetter.coverLetterText.length > 50
                  ? coverLetter.coverLetterText.substring(0, 50) + "..."
                  : coverLetter.coverLetterText}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="6xl">
        <ModalOverlay />
        <ModalContent
          alignItems="center"
          bg={colorMode === "light" ? "#fbf2f2" : "gray.800"}
        >
          <ModalBody margin={"5px"}>
            <ModalHeader />
            <Textarea
              value={selectedCoverLetter?.coverLetterText}
              readOnly
              height="80vh"
              width="50vw"
              bg={colorMode === "dark" ? "black" : "white"}
              color={colorMode === "dark" ? "white" : "black"}
              _placeholder={{
                color: colorMode === "dark" ? "gray.300" : "gray",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Tooltip label={isCopied ? "Copied" : "Copy to clipboard"}>
              <Box
                as="button"
                aria-label="Copy to clipboard"
                isDisabled={isCopied}
                _disabled={{
                  cursor: "not-allowed",
                  opacity: 0.4,
                }}
                onClick={handleCopyText}
                p={2}
                borderRadius="md"
                bg="gray.200"
                _hover={{
                  bg: "gray.300",
                }}
                _active={{
                  bg: "gray.400",
                }}
              >
                <CopyToClipboard
                  text={selectedCoverLetter?.coverLetterText}
                  onCopy={() => setIsCopied(true)}
                >
                  <IconButton
                    aria-label="Copy to clipboard"
                    icon={<FiCopy />}
                  />
                </CopyToClipboard>
              </Box>
            </Tooltip>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CoverLetterList;
