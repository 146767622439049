// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdkdQEjh48mUCIpZl6oFp4YKU7TfHmgdg",
  authDomain: "coverit-4a264.firebaseapp.com",
  projectId: "coverit-4a264",
  storageBucket: "coverit-4a264.appspot.com",
  messagingSenderId: "966490904876",
  appId: "1:966490904876:web:8e59bdfad8101bd47df489",
  measurementId: "G-VTJ1EECFJ1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, analytics, auth, db };
