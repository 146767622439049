import { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Spinner,
  Textarea,
  VStack,
  Flex,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorMode,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import ModalDialog from "../components/ModalDialog";
import { db } from "../firebase";
import { doc, setDoc, collection } from "firebase/firestore";
import SignIn from "../components/SignIn";
import CreateAccount from "../components/CreateAccount";

const UploadForm = ({ currentUser, theme }) => {
  const { colorMode } = useColorMode();
  const textareaRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showSignInComponentModal, setShowSignInComponentModal] =
    useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [text]);

  const handleSignInOpen = () => {
    setShowSignInComponentModal(true);
    setShowCreateAccountModal(false);
  };

  const handleCreateAccountOpen = () => {
    setShowSignInComponentModal(false);
    setShowCreateAccountModal(true);
  };

  const handleAddPersonalityType = (personalityData) => {
    setJobTitle(personalityData.jobTitle);
    setCompanyName(personalityData.companyName);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name); // Set the file name
  };

  const handleFileUpload = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("jobTitle", jobTitle);
    formData.append("companyName", companyName);

    axios
      .post(
        "https://us-central1-coverit-4a264.cloudfunctions.net/upload",
        formData
      )
      .then((response) => {
        console.log(response);
        setText(response.data.coverLetter);
        setLoading(false);
      });
  };

  const handleSaveCoverLetter = async () => {
    if (!currentUser) {
      setShowSignInModal(true);
      return;
    }

    try {
      await setDoc(doc(collection(db, "coverLetters")), {
        jobTitle,
        companyName,
        coverLetterText: text,
        userId: currentUser.uid,
      });

      console.log("Cover Letter saved successfully");
    } catch (error) {
      console.error("Error saving cover letter:", error);
    }
  };

  return (
    <VStack spacing={8} theme={theme} margin={"50px"}>
      <Box>
        <Input
          type="file"
          id="resume"
          onChange={handleFileChange}
          display="none"
        />
        <label htmlFor="resume">
          <Button as="span" colorScheme="purple">
            Upload Resume
          </Button>
        </label>
      </Box>

      <Box mt={4}>{fileName && <Text>Uploaded File: {fileName}</Text>}</Box>
      <Flex justify="flex-end">
        <Button
          colorScheme="purple"
          variant="ghost"
          leftIcon={<FiSettings />}
          onClick={handleModalOpen}
        >
          Add Details
        </Button>
      </Flex>
      <Button
        colorScheme="purple"
        onClick={handleFileUpload}
        disabled={!file || loading}
      >
        {loading ? (
          <Spinner size="sm" color="white" mr={2} />
        ) : (
          "Generate Cover Letter"
        )}
      </Button>

      <Box width="50vw" height="50vh">
        <Stack spacing={10}>
          <Textarea
            ref={textareaRef}
            value={text}
            placeholder="Cover letter"
            isReadOnly={loading}
            minHeight="200px"
            height="50vh"
            opacity={loading ? 0.5 : 1}
            _disabled={{ opacity: 0.5 }}
            resize="none"
            overflowY="auto"
            bg={colorMode === "dark" ? "black" : "white"}
            _placeholder={{ color: colorMode === "dark" ? "gray.300" : "gray" }}
          />
          <Button
            colorScheme="purple"
            margin={"25px"}
            onClick={handleSaveCoverLetter}
          >
            Save Cover Letter
          </Button>
        </Stack>
      </Box>

      <ModalDialog
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAddPersonalityType={handleAddPersonalityType}
      />

      <Modal isOpen={showSignInModal} onClose={() => setShowSignInModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign In or Create Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Button colorScheme="purple" onClick={handleSignInOpen}>
                Sign In
              </Button>
              <Button colorScheme="purple" onClick={handleCreateAccountOpen}>
                Create Account
              </Button>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setShowSignInModal(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SignIn
        isOpen={showSignInComponentModal}
        onClose={() => setShowSignInComponentModal(false)}
      />
      <CreateAccount
        isOpen={showCreateAccountModal}
        onClose={() => setShowCreateAccountModal(false)}
      />
    </VStack>
  );
};

export default UploadForm;
