import {
  Box,
  Flex,
  Text,
  Button,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import CreateAccount from "./CreateAccount";
import SignIn from "./SignIn";
import { auth } from "../firebase";
import React, { useState, useEffect } from "react";
import DarkModeSwitch from "./DarkModeSwitch";

const Banner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const signInDisclosure = useDisclosure();
  const [user, setUser] = useState(null);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      console.log("Signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const logoGradient =
    colorMode === "light"
      ? "linear-gradient(to right, white, magenta)"
      : "linear-gradient(to right, white, magenta)";

  return (
    <Box bg="purple" color="white" py={4}>
      <Flex alignItems="center" justifyContent="space-between" px={8}>
        <Flex alignItems="center">
          <Text
            as="h1"
            fontFamily="heading"
            fontSize="2xl"
            fontWeight="bold"
            letterSpacing="wide"
            mr={4}
            bgGradient={logoGradient}
            bgClip="text"
            // style={{ WebkitTextStroke: "1.5x black" }}
          >
            CoverIt
          </Text>

          <DarkModeSwitch />
        </Flex>
        <Flex alignItems="center">
          {user ? (
            <>
              <ChakraLink as={RouterLink} to="/" mr={4}>
                <Button colorScheme="purple">Upload</Button>
              </ChakraLink>
              <ChakraLink as={RouterLink} to="/home" mr={4}>
                <Button colorScheme="purple">Home</Button>
              </ChakraLink>
              <Button colorScheme="purple" onClick={handleSignOut}>
                Sign Out
              </Button>
            </>
          ) : (
            <>
              <Button
                colorScheme="purple"
                mr={4}
                onClick={signInDisclosure.onOpen}
              >
                Sign In
              </Button>
              <Button colorScheme="purple" onClick={onOpen}>
                Create Account
              </Button>
            </>
          )}
        </Flex>
      </Flex>

      <CreateAccount isOpen={isOpen} onClose={onClose} />
      <SignIn
        isOpen={signInDisclosure.isOpen}
        onClose={signInDisclosure.onClose}
      />
    </Box>
  );
};

export default Banner;
